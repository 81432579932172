<div class="global-buttons_container">
    <app-language class="global-button"></app-language>
    <!-- <div class="global-button" *ngIf="accountActions$ | async as accountAction">
        <div class="select-wrapper">
          <mat-icon svgIcon="profile" (click)="showOptions = !showOptions"></mat-icon>
          <ng-container *ngIf="showOptions">
            <ul class="select-options big">
                <li (click)="selectionChange(accountAction.value)">{{accountAction.viewValue}}</li>
            </ul>
        </ng-container>
      </div>
    </div> -->
    <button *ngIf="menu" class="button button--lined button--menu"
        [routerLink]="[]"
        [queryParams]="{'menu': menuOpen ? null : 'open'}"
        queryParamsHandling="merge">
        <div *ngIf="!menuOpen"><h5>{{menu}}</h5><mat-icon [svgIcon]="'menu_bars'" ></mat-icon></div>
        <mat-icon *ngIf="menuOpen" [svgIcon]="'close_big'"></mat-icon>
    </button>
</div>
