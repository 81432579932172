import {
  Component,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { Image } from './../../strapi/image';

@Component({
  selector: 'app-homecard',
  templateUrl: './homecard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeCardComponent {
  @Input() headline: string;
  @Input() subHeadline?: string;
  @Input() body: string;
  @Input() image: Image;
  @Input() colSize: number;
  @Input() link: string;
  @Input() linkText: string;
  @Input() secondLink?: string;
  @Input() secondLinkText?: string;
}
