<section class="card card--transparent">
  <app-image class="card__picture" [image]="image" [imageRatio]="{mobile: 687, tablet: 942, desktop: colSize*455}">
  </app-image>
  <div class="card__inner">
    <div *ngIf="subHeadline" class="card__sub-headline">{{subHeadline}}</div>
    <div class="card__headline">{{headline}}</div>
    <div class="card__body">
      <p>
        {{body}}
      </p>
    </div>
    <app-home-card-link [link]="link" [linkText]="linkText"></app-home-card-link>
    <app-home-card-link *ngIf="secondLink && secondLinkText" [link]="secondLink" [linkText]="secondLinkText"></app-home-card-link>
  </div>
</section>
