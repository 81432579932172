import { Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges  } from '@angular/core';
import { Breadcrumb} from './breadcrumb';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnChanges {
  @Input() crumb?: Breadcrumb;
  relativeCrumb?: Breadcrumb;

  ngOnChanges(changes: SimpleChanges): void {
    if(this.crumb) {
      this.relativeCrumb = {path: `/${this.crumb.path}`, name: this.crumb.name, params: this.crumb.params};
    } else {
      this.relativeCrumb = undefined;
    }
  }

}
