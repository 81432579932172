import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StrapiService } from '../../strapi/strapi.service';
import { MenuItem, partitionMenuItems, PartitionedMenuItems } from './menu-item';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnChanges {
  data$: Observable<PartitionedMenuItems>;
  @Input() menuItems?: MenuItem[];
  @Input() dark: boolean = true;
  @Input() showScrollUp: boolean = true;

  constructor(private window: Window, private strapiService: StrapiService) {}

  ngOnChanges(): void {
    if(this.menuItems) {
      this.data$ = of(partitionMenuItems(this.menuItems.filter(i => !i.hideInFooter)));
    } else {
      this.data$ = this.strapiService.getMenuItems().pipe(map( items => partitionMenuItems(items.filter(i => !i.hideInFooter))));
    }
  }

  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  darkBackground(dark: boolean) {
    if(dark) {
      return 'dark-background';
    } else {
      return '';
    }
  }
}
